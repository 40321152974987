const isSubscribed = user => user && user.subscribed === true;

const canAnalyse = user => isSubscribed(user) || user.credits > 0;

export const User = {
  isSubscribed,
  canAnalyse,
};

export const goToStripeBilling = user => {
  window.location.href = `https://billing.stripe.com/p/login/${
    process.env.STRIPE_BILLING_ID
  }?prefilled_email=${user.email}`;
};
