/*
 * Pricing Messages
 *
 * This contains all the text for the Pricing container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Pricing';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Pricing',
  },
  pricingDescription: {
    id: `${scope}.pricingDescription`,
    defaultMessage:
      'Choose from flexible pricing options designed to fit your business needs and budget.',
  },
  pricingHeader: {
    id: `${scope}.pricingHeader`,
    defaultMessage: 'Pricing',
  },
  perMonth: {
    id: `${scope}.perMonth`,
    defaultMessage: 'per month',
  },
  perYear: {
    id: `${scope}.perYear`,
    defaultMessage: 'per Year',
  },
  basicMonthlyTitle: {
    id: `${scope}.basicMonthlyTitle`,
    defaultMessage: 'Basic - Monthly Plan',
  },
  basicAnnualTitle: {
    id: `${scope}.basicAnnualTitle`,
    defaultMessage: 'Basic - Annual Plan',
  },
  enterpriseTitle: {
    id: `${scope}.enterpriseTitle`,
    defaultMessage: 'Enterprise',
  },
  pack1Title: {
    id: `${scope}.pack1Title`,
    defaultMessage: 'Pack 1 Credit',
  },
  pack5Title: {
    id: `${scope}.pack5Title`,
    defaultMessage: 'Pack 5 Credits',
  },
  pack10Title: {
    id: `${scope}.pack10Title`,
    defaultMessage: 'Pack 10 Credits',
  },
  subscribe: {
    id: `${scope}.subscribe`,
    defaultMessage: 'Subscribe',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact Us',
  },
  buyCredits: {
    id: `${scope}.buyCredits`,
    defaultMessage: 'Buy Credits',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage:
      'Reports: View and share comprehensive reports of your analyses',
  },
  properties: {
    id: `${scope}.properties`,
    defaultMessage: 'Properties: Add and organize unlimited properties.',
  },
  customAnalyses: {
    id: `${scope}.customAnalyses`,
    defaultMessage:
      'Analyses: Use your credits to create and edit investment analyses.',
  },
  analyses: {
    id: `${scope}.analyses`,
    defaultMessage: 'Analyses: Create and edit unlimited analyses.',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account: Easily update and manage your account.',
  },
  credits: {
    id: `${scope}.credits`,
    defaultMessage: 'Buy Credits',
  },
  basicSupport: {
    id: `${scope}.basicSupport`,
    defaultMessage: 'Support: Email support.',
  },
  fullSupport: {
    id: `${scope}.fullSupport`,
    defaultMessage: 'Support: Email and WhatsApp support.',
  },
  subscription: {
    id: `${scope}.subscription`,
    defaultMessage: 'Choose a Subscription',
  },
  billedMonthly: {
    id: `${scope}.billedMonthly`,
    defaultMessage: 'Billed monthly.',
  },
  billedAnnual: {
    id: `${scope}.billedAnnual`,
    defaultMessage: 'Billed annually.',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save 16.67% - equivalent to €37.50/month.',
  },
  exclusive: {
    id: `${scope}.exclusive`,
    defaultMessage: 'Gain exclusive features for you and/or your company.',
  },
  allFeatures: {
    id: `${scope}.allFeatures`,
    defaultMessage: 'Includes all features of the Basic plan.',
  },
  customizedSolutions: {
    id: `${scope}.customizedSolutions`,
    defaultMessage:
      'Customized Solutions: Tailored to your or your company’s needs.',
  },
  customIntegration: {
    id: `${scope}.customIntegration`,
    defaultMessage: 'Custom Integration: Connect with existing systems.',
  },
  businessSupport: {
    id: `${scope}.businessSupport`,
    defaultMessage: 'Business Support: Dedicated assistance for businesses.',
  },
  training: {
    id: `${scope}.training`,
    defaultMessage: 'Personalized Training: Specific training for your team.',
  },
});
