import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import LanguageProvider from 'containers/LanguageProvider';
// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import { AuthProvider } from 'containers/AuthenticationProvider/JwtContext';
import App from 'containers/App';
import { SettingsProvider } from 'components/Settings';
import { MotionLazyContainer } from 'components/Animate';
import configureStore from './configureStore';
import { translationMessages } from './i18n';
// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import posthog from 'posthog-js';
import * as Swetrix from 'swetrix';
// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

// Inject store into axios interceptors
// globalAxiosInterceptors(store);

const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  ReactDOM.render(
    <AuthProvider>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <SettingsProvider>
            <BrowserRouter>
              <MotionLazyContainer>
                <App style={{ height: '100%' }} store={store} />
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </LanguageProvider>
      </Provider>
    </AuthProvider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

SentryBrowser.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    SentryBrowser.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
});

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    /^https:\/\/api\.revalue\.pt/,
    /^https:\/\/api\.dev\.revalue\.pt/,
    /^https:\/\/revalue\.pt/,
    /^https:\/\/dev\.revalue\.pt/,
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // eslint-disable-next-line consistent-return
  beforeSend(event, hint) {
    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length === 1
    ) {
      const e = event.exception.values[0];
      if (
        e.type === 'UnhandledRejection' &&
        e.value === 'Non-Error promise rejection captured with value: '
      ) {
        return null;
      }
    }
  },
});

posthog.init(process.env.POSTHOG_KEY, {
  api_host: process.env.POSTHOG_HOST,
  person_profiles: 'identified_only',
});

Swetrix.init('1xnFyDXycbG4');

try {
  Swetrix.trackViews();
  Swetrix.trackErrors({
    sampleRate: 1,
    callback: undefined,
  });
} catch (error) {
  console.error('Swetrix tracking failed:', error);
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
