import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { PATH_PAGE, PATH_AUTH } from 'routes/paths';
import BuyButton from 'components/BuyButton';
import messages from 'pages/Pricing/messages';

export default function PricingCards({
  intl,
  user,
  isHomepage,
  customerSession,
}) {
  const [isSubscription, setIsSubscription] = React.useState(true);

  const navigate = useNavigate();

  const parseEnvVariable = envVar => {
    const [id, price] = envVar.split(';');
    return { id, price };
  };

  const subscriptionTiers = [
    {
      ...parseEnvVariable(
        process.env.STRIPE_BUY_BUTTON_ID_SUBSCRIPTION_MONTHLY,
      ),
      titleKey: 'basicMonthlyTitle',
      isPrimary: false,
      descriptionKeys: [
        'billedMonthly',
        'analyses',
        'reports',
        'properties',
        'account',
        'fullSupport',
      ],
      priceSuffix: intl.formatMessage(messages.perMonth),
      buttonKey: 'subscribe',
      buttonVariant: 'outlined',
      buttonColor: 'primary',
      useBuyButton: true,
      onClick: () => {
        navigate(PATH_AUTH.signUp);
      },
    },
    {
      ...parseEnvVariable(process.env.STRIPE_BUY_BUTTON_ID_SUBSCRIPTION_YEARLY),
      titleKey: 'basicAnnualTitle',
      isPrimary: true,
      descriptionKeys: [
        'billedAnnual',
        'save',
        'analyses',
        'reports',
        'properties',
        'account',
        'fullSupport',
      ],
      priceSuffix: intl.formatMessage(messages.perYear),
      buttonKey: 'subscribe',
      buttonVariant: 'contained',
      buttonColor: 'secondary',
      useBuyButton: true,
      onClick: () => {
        navigate(PATH_AUTH.signUp);
      },
    },
    {
      titleKey: 'enterpriseTitle',
      isPrimary: false,
      descriptionKeys: [
        'exclusive',
        'allFeatures',
        'customizedSolutions',
        'customIntegration',
        'businessSupport',
        'training',
      ],
      customPricing: true,
      buttonKey: 'contactUs',
      buttonVariant: 'outlined',
      buttonColor: 'primary',
      useBuyButton: false,
      onClick: () => {
        navigate(`/${PATH_PAGE.contact}`);
      },
    },
  ];

  const creditTiers = [
    {
      ...parseEnvVariable(process.env.STRIPE_BUY_BUTTON_ID_PACK_1),
      titleKey: 'pack1Title',
      isPrimary: false,
      descriptionKeys: [
        'customAnalyses',
        'reports',
        'properties',
        'account',
        'basicSupport',
      ],
      buttonKey: 'buyCredits',
      buttonColor: 'primary',
      buttonVariant: 'outlined',
      useBuyButton: true,
      onClick: () => {
        navigate(PATH_AUTH.signUp);
      },
    },
    {
      ...parseEnvVariable(process.env.STRIPE_BUY_BUTTON_ID_PACK_5),
      titleKey: 'pack5Title',
      isPrimary: true,
      descriptionKeys: [
        'customAnalyses',
        'reports',
        'properties',
        'account',
        'basicSupport',
      ],
      buttonKey: 'buyCredits',
      buttonVariant: 'contained',
      buttonColor: 'secondary',
      useBuyButton: true,
      onClick: () => {
        navigate(PATH_AUTH.signUp);
      },
    },
    {
      ...parseEnvVariable(process.env.STRIPE_BUY_BUTTON_ID_PACK_10),
      titleKey: 'pack10Title',
      isPrimary: false,
      descriptionKeys: [
        'customAnalyses',
        'reports',
        'properties',
        'account',
        'basicSupport',
      ],
      buttonKey: 'buyCredits',
      buttonVariant: 'outlined',
      buttonColor: 'primary',
      useBuyButton: true,
      onClick: () => {
        navigate(PATH_AUTH.signUp);
      },
    },
  ];

  return (
    <Container
      id="pricing"
      sx={{
        padding: '70px 0px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          {intl.formatMessage(messages.pricingHeader)}
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {intl.formatMessage(messages.pricingDescription)}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
            {intl.formatMessage(messages.credits)}
          </Typography>
          <Switch
            checked={isSubscription}
            onChange={() => setIsSubscription(!isSubscription)}
            color="primary"
          />
          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
            {intl.formatMessage(messages.subscription)}
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {(isSubscription ? subscriptionTiers : creditTiers).map(
          (tier, index) => (
            <Grid item xs={12} sm={12} md={4} key={`${tier.id}-${index}`}>
              <Card
                sx={[
                  {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                  },
                  tier.isPrimary &&
                    (theme => ({
                      border: 'none',
                      background:
                        'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                      ...theme.applyStyles('dark', {
                        background:
                          'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                        boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={{
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      sx={{
                        color: tier.isPrimary ? 'white' : 'text.primary',
                      }}
                    >
                      {intl.formatMessage(messages[tier.titleKey])}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    {tier.price && (
                      <Typography
                        component="h3"
                        variant="h2"
                        sx={{
                          color: tier.isPrimary ? 'white' : 'text.primary',
                        }}
                      >
                        {tier.price}€
                      </Typography>
                    )}
                    {tier.priceSuffix && (
                      <Typography
                        component="h3"
                        variant="h6"
                        sx={{
                          color: tier.isPrimary ? 'white' : 'text.secondary',
                        }}
                      >
                        &nbsp;{tier.priceSuffix}
                      </Typography>
                    )}
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }}
                  />
                  {tier.descriptionKeys.map(key => (
                    <Box
                      key={key}
                      sx={{
                        py: 1,
                        display: 'flex',
                        gap: 1.5,
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={{
                          width: 20,
                          color: tier.isPrimary
                            ? 'primary.light'
                            : 'primary.main',
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{
                          color: tier.isPrimary ? 'grey.50' : null,
                        }}
                      >
                        {intl.formatMessage(messages[key])}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions
                  sx={{
                    justifyContent: 'center',
                    mt: 2,
                  }}
                >
                  {isHomepage || tier.customPricing ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color={tier.buttonColor}
                      onClick={tier.onClick}
                    >
                      {intl.formatMessage(messages[tier.buttonKey])}
                    </Button>
                  ) : (
                    <BuyButton
                      buyButtonId={tier.id}
                      user={user}
                      customerSession={customerSession}
                    />
                  )}
                </CardActions>
              </Card>
            </Grid>
          ),
        )}
      </Grid>
    </Container>
  );
}
