import { Typography } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import Button from '@mui/material/Button';
import { goToStripeBilling } from 'utils/userUtils';
import PricingCards from 'pages/Pricing/components/PricingCards';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import { getCustomerSession } from './actions';
import {
  makeSelectCustomerSession,
  makeSelectIsGettingCustomerSession,
} from './selectors';
import messages from './messages';
import { RefreshPageLabel } from './components/refreshPageLabel';

const Subscription = props => {
  useInjectReducer({ key: 'subscription', reducer });

  const { user } = props;

  useEffect(() => {
    props.getCustomerSession();
  }, []);

  return (
    <>
      {!props.isGettingCustomerSession && (
        <PricingCards
          intl={props.intl}
          user={user}
          customerSession={props.customerSession}
        />
      )}
      <RefreshPageLabel intl={props.intl} />
      <div
        style={{
          margin: '60px 0px',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography style={{ fontSize: '1rem' }}>
          {props.intl.formatMessage(messages.managePayments)}
        </Typography>
        <Button
          style={{ margin: '30px' }}
          variant="contained"
          onClick={() => goToStripeBilling(user)}
        >
          {props.intl.formatMessage(messages.goToBilling)}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  customerSession: makeSelectCustomerSession(),
  isGettingCustomerSession: makeSelectIsGettingCustomerSession(),
});

function mapDispatchToProps(dispatch) {
  return {
    getCustomerSession: () => dispatch(getCustomerSession()),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
  injectIntl,
)(Subscription);
