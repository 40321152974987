import React, { useEffect, useRef } from 'react';
import ThemeProvider from 'containers/ThemeProvider';
import Router from 'routes';
import { useRemoveTrailingSlash } from 'hooks/useRemoveTrailingSlash';
import { CheckForIndexHTMLChange } from 'utils/checkForIndexHtmlChange';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import * as Sentry from '@sentry/browser';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useAuthContext } from '../AuthenticationProvider/useAuthContext';

export function App(props) {
  const tawkMessengerRef = useRef();

  useEffect(() => {
    let reloadOnNextChange = false;

    const clearCacheAndReload = () => {
      console.info(
        "Reloading to get the latest version of the app's index.html",
      );
      caches
        .keys()
        .then(names => names.forEach(name => caches.delete(name)))
        .then(() => {
          window.localStorage.clear();
          window.location.reload();
          window.location.href = `${
            window.location.href.split('?')[0]
          }?_=${Date.now()}`;
        })
        .catch(err => console.error('Error clearing cache:', err));
    };

    const checkAndSetReload = async () => {
      try {
        const { host } = window.location;
        reloadOnNextChange = await CheckForIndexHTMLChange(
          `https://${host}/index.html`,
        );
        console.info(
          reloadOnNextChange
            ? 'Will reload on next check if necessary.'
            : 'Cache is up to date. No reload necessary.',
        );
      } catch (error) {
        console.error('Error checking for index.html changes:', error);
      }
    };

    // Run the check initially
    checkAndSetReload();

    // Polling for changes
    const reloadCheckInterval = setInterval(() => {
      if (reloadOnNextChange) {
        clearCacheAndReload();
        reloadOnNextChange = false;
      } else {
        checkAndSetReload();
      }
    }, 20000); // Check every 20 seconds

    return () => {
      clearInterval(reloadCheckInterval);
    };
  }, []);

  useRemoveTrailingSlash();

  const { intl } = props;
  let widgetId = '';

  if (intl.locale === 'pt') {
    widgetId = process.env.TAWK_WIDGET_PT_ID;
  } else if (intl.locale === 'en') {
    widgetId = process.env.TAWK_WIDGET_EN_ID;
  }

  const { user } = useAuthContext();

  useEffect(() => {
    const setTawkAttributes = () => {
      try {
        if (
          window.Tawk_API &&
          typeof window.Tawk_API.setAttributes === 'function' &&
          user &&
          user.email
        ) {
          window.Tawk_API.setAttributes(
            {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
            },
            error => {
              if (error) {
                console.error('Tawk.to API Error:', error);
              }
            },
          );
        } else {
          console.warn('Tawk.to API not ready or attributes not available.');
        }
      } catch (error) {
        console.error('Error setting Tawk.to attributes:', error);
      }
    };

    if (window.Tawk_API) {
      // Wait for the Tawk API to be fully loaded
      if (typeof window.Tawk_API.onLoad === 'function') {
        window.Tawk_API.onLoad = () => {
          setTawkAttributes();
        };
      } else {
        // If Tawk API is already loaded, directly call the function
        setTawkAttributes();
      }
    } else {
      console.error('Tawk.to API is not loaded.');
    }

    // Set user in Sentry
    try {
      if (user && user.email) {
        Sentry.setUser({ email: user.email });
      } else {
        Sentry.setUser(null);
      }
    } catch (error) {
      console.error('Error setting Sentry user:', error);
    }

    // Identify the user in Hotjar if initialized
    if (user && user.email) {
      try {
        // Hotjar Integration
        if (window.hj) {
          window.hj('identify', user.email, {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          });
        } else {
          console.warn('Hotjar is not initialized.');
        }
      } catch (error) {
        console.error('Error identifying Hotjar user:', error);
      }
    }

    // PostHog Integration
    try {
      if (user && user.email) {
        posthog.identify(user.email, {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
        });
      }
    } catch (e) {
      console.error('PostHog Error:', e);
    }
  }, [user]);

  return (
    <>
      <PostHogProvider client={posthog}>
        <TawkMessengerReact
          propertyId={process.env.TAWK_PROPERTY_ID}
          widgetId={widgetId}
          ref={tawkMessengerRef}
        />
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </PostHogProvider>
    </>
  );
}

export default compose(injectIntl)(App);
