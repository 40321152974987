import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthContext } from 'containers/AuthenticationProvider/useAuthContext';
import messages from 'pages/Default/messages';
import Subscription from '../Subscription';

export default function SubscriptionModal({ open, handleClose, intl }) {
  const { user, getCredits } = useAuthContext();

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
        getCredits();
      }}
    >
      <DialogTitle>
        {!user.credits
          ? intl.formatMessage(messages.outOfCredits)
          : intl.formatMessage(messages.creditsAndSubscription)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          handleClose();
          getCredits();
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            getCredits();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Subscription />
      </DialogContent>
    </Dialog>
  );
}
